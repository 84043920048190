<template>
  <div>
    <div class="row align-items-end">
      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for="">Catégorie</label>
        <vue-select 
          :options="sections"
          v-model="section_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
        />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for="">Produit</label>
        <vue-select 
          :options="filteredItems"
          v-model="orderItem.item_reference"
          placeholder="Select an option"
          labelColumn="fullName"
          valueColumn="reference"
        />
      </div>

       <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Stock <span class="text-danger">*</span> </label>
        <select
          v-model="orderItem.stockItem_reference"
          class="form-select"
         
        >
          <option value="" selected>Créer un nouveau stock</option>
          <option
            v-for="(stock, index) in filteredStockItems"
            :key="index"
            :value="stock.reference"
            :selected="true"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }}
          </option>
        </select>
      
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Désignation de stock </label>
        <select
          v-model="orderItem.stock_reference"
          class="form-select"
          :disabled="orderItem.stockItem_reference!=''"
        >
          <option
            v-for="(stock, index) in stocks"
            :key="index++"
            :value="stock.reference"
            :selected="true"
          >
            {{ stock.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Remarque </label>
        <input v-model="orderItem.remark" class="form-control" />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Qté </label>
        <input type="text" v-model="orderItem.quantity" class="form-control" />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Prix </label>
        <input type="text" v-model="orderItem.priceTTC" class="form-control" />
      </div>

     
    </div>
    <div class="row justify-content-end">
      <div class="col-auto my-3">
        <button
          v-if="orderItem"
          @click="save(orderItem)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-plus-circle"></i>
          Enregistrer
        </button>
      </div>
      <div class="col-auto my-3">
        <button @click="refresh()" class="btn  btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
    </div>

    
    <vue-data-table
        :headers="headers"
        :data="orderItems"
        :itemsPerPage="10"
        :buttons="buttons"
      />

    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          type="button"
          @click="$router.push({ name: 'orders-suppliers-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-arrow-right-circle"></i>
          Suivant
        </button>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      orderItem: {},
      section_reference: "",
      accept: false,
      headers: [
        { label: 'Désignation', field: 'itemFullName' },
        { label: 'Quantité', field: 'quantity' },
        { label: 'Prix', field: 'priceTTC' },
        { label: 'Total', field: 'totalPriceTTC' },
      ],
      buttons: [
        {
          label: 'Supprimer',
          icon: 'bi bi-trash ',
          class:'btn btn-danger',
          action: (row) => {
            // remove item logic here
          },
        },
      ],
      summableColumns: [
        { field: 'quantity' },
        { field: 'totalPriceTTC' },
      ],

    };
  },
  computed: {
    ...mapGetters("order", {
      order: "getSupplierOrder",
      
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("stock", {
      stocks: "getStocks",
       stockItems: "getStockItems",
    }),
   

    filteredItems() {
      if (this.section_reference == "") {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
    filteredStockItems() {
      if (this.orderItem.item_reference)
        return this.stockItems.filter(
          (stockItem) => stockItem.item_reference == this.orderItem.item_reference
        );
      return null;
    },

    orderItems() {
      return this.order.order_items
    }
  },
  async created() {
    await this.$store.dispatch(
      "order/showSupplierOrder",
      this.$route.params.reference
    );
    this.$store.dispatch("order/getSupplierOrders");
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("stock/fetchStocksItems");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
    
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(
        "order/showSupplierOrder",
        this.$route.params.reference
      );
      this.$store.dispatch("order/getSupplierOrders");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("stock/getAll");
      this.$store.dispatch("stock/fetchStocksItems");
    },

    async save(data) {
      await this.$store.dispatch("order/storeOrderSupplierItem", data);
      await (this.orderItem = {});
      await this.$store.dispatch("stock/fetchStocksItems");
    },

    async removeItem(id) {
      return await this.$store.dispatch("order/destroyOrderSupplierItem", id);
    },
    async editOrderItem(data){
      this.orderItem = {
        item_reference:data.item_reference,
        quantity:data.quantity,
      }
    }
  },
};
</script>
